.layout {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.layout > .header {
  flex: 1 0 auto;
}

.layout > .content {
  width: 98.5%;
}
/* Add this CSS to your stylesheet */
.chip-container {
    max-width: 100px; /* Adjust the max-width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: max-width 0.3s ease-in-out;
}

.eds-chip{
    min-width: 100px;
}

/* Add this CSS to your stylesheet */
.microservice-container {
    max-width: 150px; /* Adjust the max-width as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    transition: max-width 0.3s ease-in-out;
}

.futura-app-header .eds-app-header__name {
    font: var(--eds-type-display-4) !important;
}

.mainContentLayout {
    margin: 16px;
    gap: 16px;
}
